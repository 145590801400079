<template>
  <templateComponent>
    <div class="ao">
      <div class="ao-title">
        <img src="@/assets/images/AFFOverview.png" alt="">
        <span>{{ $t("AFF合作计划介绍") }}</span>
      </div>
      <div class="ao-msg" v-for="item in list" :key="item.id">
        <img :src="item.icon" class="ao-msg-avatar">
        <div class="ao-msg-right">
          <div class="ao-msg-content html" v-html="item.content"></div>
          <div class="ao-msg-anwsert" @click="openUrl(son.rec_link)" v-for="son in item.son" :key="son.content">
            <span v-html="son.content"></span>
            <i class="el-icon-arrow-right el-icon"></i>
          </div>
        </div>
        
      </div>
      <none v-if="list.length === 0"></none>
    </div>
  </templateComponent>
</template>

<script>
import templateComponent from '@/components/template.vue';
import {news_agent_list_api} from "@/api/news"
import {Loading} from "element-ui"
export default {
  data(){
    return {
      list:[]
    }
  },
  components: { templateComponent },
  methods:{
    async getList(){
      const {data:res} =  await news_agent_list_api()
      if(res.code==1){
        console.log(res.data)
        this.list = res.data
      }
    }
  },
  async mounted(){
    const loadingCtx = Loading.service();
    await this.getList()
    loadingCtx.close()
  }
}
</script>

<style scoped lang="less" src="@/assets/css/AFFOverview.less"></style>